@import "./style/app-style.scss";

html {
    height: 100%;
    background-color: $body-background-color;
}
body {
    height: 100%;
    margin: 0;
}

.hidden-file {
    display: none;
}
